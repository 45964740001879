<template>
  <div class="page-view">
    <register-mob v-if="isMobile"></register-mob>
    <register-pc v-else></register-pc>
  </div>
</template>

<script>
import RegisterPc from "./register-pc.vue";
import RegisterMob from "./register-mob.vue";
export default {
  components: {
    RegisterPc,
    RegisterMob,
  },
  metaInfo: {
    title: "注册",
  },
  data() {
    return {
      isMobile: this.$isMobile(),
    };
  },
  beforeCreate() {
    // console.log(sessionStorage.getItem("clause"));
    if (sessionStorage.getItem("clause")) {
      return;
    } else {
      this.$router.replace({ path: "/clause" });
    }
    // if (sessionStorage.getItem("clause") != true) {
    //   this.$router.replace({path: "/clause"});
    // }
  },
};
</script>
