<template>
  <div class="page-view">
    <div class="top">
      <div class="logo"></div>
      <div class="title">用户注册</div>
    </div>
    <div class="main">
      <div class="form">
        <yl-input v-model="formData.username" placeholder="请输入姓名"
          ><img src="../../assets/name.png" alt=""
        /></yl-input>
        <yl-input v-model="formData.phone" placeholder="请输入手机号"
          ><img src="../../assets/phone.png" alt=""
        /></yl-input>

        <input type="password" style="display: none" />
        <yl-input
          v-model="formData.password"
          type="password"
          placeholder="请输入密码"
          ><img src="../../assets/password.png" alt=""
        /></yl-input>
        <yl-input v-model="formData.organization" placeholder="请输入工作单位"
          ><img src="../../assets/company.png" alt=""
        /></yl-input>
        <yl-input v-model="formData.email" placeholder="请输入电子邮箱"
          ><img src="../../assets/email.png" alt=""
        /></yl-input>
        <yl-picker
          v-model="formData.jobfunction"
          :dataList="jobFunctionData"
          placeholder="请选择您的工作职能"
        >
          <img src="../../assets/job.png" alt="" />
        </yl-picker>
        <yl-picker
          v-model="formData.interest"
          :multiple="true"
          :dataList="interestData"
          placeholder="请选择感兴趣的领域"
        >
          <img src="../../assets/interest.png" alt="" />
        </yl-picker>
        <yl-picker
          v-model="formData.enquiry"
          :dataList="enquiryData"
          placeholder="您是否需要询价或联系销售代表？"
        >
          <img src="../../assets/myAppointment.png" alt="" />
        </yl-picker>
      </div>
      <div class="clause">
        <!-- <div class="info">
          如果您通过点击以下按钮发送数据，则表示您同意向我们提供上述信息。这些信息将受到Illumina隐私政策的保护，点击获取<a
            href="https://www.illumina.com.cn/company/legal/privacy.html"
            >Illumina的隐私政策</a
          >。
        </div>
        <div class="select">
          <div class="icon" @click="imgVisible = !imgVisible">
            <img v-if="imgVisible" src="../../assets/true.png" alt="" />
          </div>
          <div class="tip">我已经阅读并了解本条款</div>
        </div> -->
        <button @click="submitFn()">注册并登录</button>
      </div>
    </div>
  </div>
</template>

<script>
import YlInput from "../../components/yl-input.vue";
import YlPicker from "../../components/yl-picker.vue";
import { registerAccount, wechatConfig } from "../../api/user";
import { setToken } from "../../utils/auth";
import wechat from "../../utils/wxShare";
export default {
  components: {
    YlInput,
    YlPicker,
  },
  data() {
    return {
      imgVisible: false,
      formData: {
        username: "",
        phone: "",
        password: "",
        organization: "",
        email: "",
        jobfunction: "",
        interest: "",
        enquiry: "",
      },
      otherData: {
        sourcetag: sessionStorage.getItem("st")
          ? sessionStorage.getItem("st")
          : "illumina",
        registertype: this.$route.query.r ? this.$route.query.r : "",
        registercourse: this.$route.query.c ? this.$route.query.c : "",
        wxopenid: "",
        wxnickname: "",
        wxheadimg: "",
        device: this.$device(),
      },
      formObj: {},
      jobFunctionData: [
        [
          { label: "行政 - 其他", value: "行政 - 其他" },
          { label: "行政 - 采购代理人", value: "行政 - 采购代理人" },
          {
            label: "生物信息学/信息技术人员",
            value: "生物信息学/信息技术人员",
          },
          { label: "临床医生", value: "临床医生" },
          { label: "主管", value: "主管" },
          { label: "实验室主任", value: "实验室主任" },
          { label: "实验室人员", value: "实验室人员" },
          { label: "非科研人员", value: "非科研人员" },
          { label: "其他医学专业人员", value: "其他医学专业人员" },
          { label: "合作伙伴", value: "合作伙伴" },
          { label: "病理学家", value: "病理学家" },
          { label: "医师", value: "医师" },
          { label: "首席研究员", value: "首席研究员" },
          { label: "教授", value: "教授" },
          { label: "学生", value: "学生" },
        ],
      ],
      interestData: [
        "农业研究",
        "动物测试",
        "应用微生物学",
        "自体免疫性疾病研究",
        "癌症研究",
        "心血管疾病研究",
        "细胞与分子生物学研究",
        "临床肿瘤学",
        "消费者基因组学",
        "遗传与复杂疾病研究",
        "遗传健康",
        "代谢疾病研究",
        "微生物学/病毒学研究",
        "神经科学研究",
        "非侵入性产前检测",
        "植物检测",
        "孕前与生育保健",
        "公共卫生",
        "罕见及遗传性疾病",
      ],
      enquiryData: [
        [
          { label: "是的，我需要", value: "是的，我需要" },
          {
            label: "不需要，发送产品资料即可",
            value: "不需要，发送产品资料即可",
          },
        ],
      ],
      phoneReg: /^[1][3-9]\d{9}$|^([6|9])\d{7}$|^[0][9]\d{8}$|^6\d{5}$/,
      passwordReg: /^[a-zA-Z0-9]{6,12}$/,
      emailReg:
       /^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,4}$/,
      registerFlag: true,
    };
  },
  created() {
    this.$wxAuth();
    let wxUserInfo = JSON.parse(sessionStorage.getItem("wxUser"));
    if (wxUserInfo) {
      this.otherData.wxheadimg = wxUserInfo.wxHeadImg;
      this.otherData.wxnickname = wxUserInfo.wxNickName;
      this.otherData.wxopenid = wxUserInfo.openid;
    }
    this.getWechatConfig();
  },
  methods: {
    getWechatConfig() {
      wechatConfig({
        url: location.href,
      }).then((res) => {
        if (res.code == 0) {
          this.wxConfigData = res.data;
          wechat.share(
            this.wxConfigData,
            "因美纳在线讲堂",
            "因美纳在线讲堂",
            this.$store.state.url,
            location.href
          );
        }
      });
    },
    submitFn() {
      for (var key in this.formData) {
        if (this.formData[key] != "0" && !this.formData[key]) {
          this.$ylmessage.error("您的信息尚未填写，请补全信息");
          return;
        } else {
          // 正则验证
          if (key == "phone" && !this.phoneReg.test(this.formData[key])) {
            this.$ylmessage.error("请输入正确的手机号");
            return;
          } else if (
            key == "password" &&
            !this.passwordReg.test(this.formData[key])
          ) {
            this.$ylmessage.error("密码由6~12位字母数字组合");
            return;
          } else if (
            key == "email" &&
            !this.emailReg.test(this.formData[key])
          ) {
            this.$ylmessage.error("请输入正确的电子邮箱");
            return;
          }
        }
      }
      // if (!this.imgVisible) {
      //   this.$ylmessage.error("请勾选隐私政策条款");
      // } else {
      Object.assign(this.formObj, this.formData, this.otherData);
      if (this.registerFlag) {
        this.registerFlag = false;
        registerAccount(this.formObj).then((res) => {
          if (res.code == 0) {
            this.$ylmessage.success("注册成功");
            setToken(res.token);
            setTimeout(() => {
              if (this.$route.query.w) {
                this.$router.push({
                  path: "/watch/" + this.$route.query.w,
                  query: { st: this.otherData.sourcetag },
                });
              } else {
                this.$router.push({ path: "/" });
              }
            }, 1500);
          } else {
            this.$ylmessage.error(res.msg);
          }
          this.registerFlag = true;
        });
      }
      // }
    },
  },
};
</script>

<style lang="scss" scoped>
.page-view {
  background: url("../../assets/h5bg.png") 0 0 no-repeat;
  background-size: 100% auto;
  .top {
    height: 1.35rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .logo {
      width: 1.84rem;
      height: 0.25rem;
      background: url("../../assets/logo.png") 0 0 no-repeat;
      background-size: 100% 100%;
    }
    .title {
      font-size: 0.16rem;
      font-weight: bold;
      color: #fff;
      margin-top: 0.4rem;
    }
  }
  .main {
    padding: 0 0.26rem;
    .form {
      padding: 0.22rem 0.18rem;
      background-color: #fff;
      border-radius: 6px;
      .yl-picker:last-child {
        margin-bottom: 0;
      }
    }
    .clause {
      color: #b2b2b2;
      font-size: 0.12rem;
      .info {
        padding: 0.14rem 0;
        text-align: justify;
        a {
          text-decoration: none;
          font-weight: bold;
          color: #1872bb;
        }
      }
      .select {
        display: flex;
        .icon {
          width: 0.18rem;
          height: 0.18rem;
          border-radius: 3px;
          overflow: hidden;
          background-color: #b2b2b2;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .tip {
          padding-left: 0.1rem;
        }
      }
      button {
        width: 100%;
        height: 0.4rem;
        border: none;
        background-color: #1772bb;
        border-radius: 0.2rem;
        font-size: 16px;
        color: #fff;
        margin: 0.32rem 0 0.4rem;
      }
    }
  }
}
</style>
